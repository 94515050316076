// The base theme for inmotion.

//@import "../../../target/web/web-modules/main/webjars/lib/bootswatch/dist/sandstone/variables";
@import "../../../ui/src/assets/app-theme";
@import "../../../target/web/web-modules/main/webjars/lib/bootstrap/scss/bootstrap";
//@import "../../../target/web/web-modules/main/webjars/lib/bootswatch/dist/sandstone/bootswatch";

// BODY
$TOP_PADDING: 0px;

// FOOTER
$FOOTER_TEXT_COLOUR: white;
$FOOTER_HANDHELD_TEXT_SIZE: 11px;
$FOOTER_TEXT_SIZE: 11px;
$FOOTER_BG_COLOUR: $primary;
$FOOTER_BG_HEIGHT: 36px;
$AUTH_PANEL_BG_COLOUR: darken($primary, 5);

$BACKGROUND: $gray-100;

html, body {
  padding-top: $TOP_PADDING;
  overflow-y: auto;
}

@media (min-width: 768px) {
  html, body {
    padding-top: $TOP_PADDING;
  }
}

.unsecured-body {
  padding-top: 0;
  background-color: $primary;
  overflow: auto;
}

@media (min-width: 768px) {
  .unsecured-body {
    padding-top: 0;
    background-color: $primary;
    overflow: auto;
  }
}

.secured-body {
  background-color: $BACKGROUND !important;
  overflow: hidden;
}

@media (min-width: 768px) {
  .secured-body {
    background-color: $BACKGROUND !important;
    overflow: hidden;
  }
}

.navbar {
  background: linear-gradient(to bottom right, darken($primary, 5), lighten($primary, 5));
}

.nav-title {
  display: inline-block;
  width: 100%;
  font-family: 'Verdana', sans-serif;
  color: white;
  font-size: 20px;
  text-align: left;
  font-weight: bold;
  font-style: italic;
}

.logo-banner {
  width: 100%;
  font-family: 'Verdana', sans-serif;
  color: $light;
  font-size: 30px;
  text-align: left;
  font-weight: bold;
  font-style: italic;
}

.auth-box-wide {
  min-width: 400px;
  max-width: 400px;
  width: 100%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

@media (min-width: 768px) {
  .auth-box-wide {
    min-width: 400px;
    max-width: 800px;
    width: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.auth-box {
  min-width: 400px;
  max-width: 400px;
  width: 100%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

@media (min-width: 768px) {
  .auth-box {
    min-width: 400px;
    max-width: 600px;
    width: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.auth-panel {
  top: 0;
  background: linear-gradient(to bottom right, darken($AUTH_PANEL_BG_COLOUR, 3), lighten($AUTH_PANEL_BG_COLOUR, 4));
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 8px;
  color: $light;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
}

.auth-title {
  color: $light;
  font-size: 24px;
  text-align: left;
}

.auth-question-link {
  color: $light;
  font-size: 14px;
  border-color: white;
}

.auth-question-link a {
  color: $light;
}

.auth-alt-link {
  color: $light;
  font-size: 14px;
  text-align: center;
}

.auth-alt-link a {
  color: $light;
}

.about-box {
  min-width: 400px;
  max-width: 400px;
  width: 100%;
  transform: translate(-50%, 0%);
  position: absolute;
  top: 100px;
  left: 50%;
}

@media (min-width: 768px) {
  .about-box {
    min-width: 400px;
    max-width: 768px;
    width: 100%;
    transform: translate(-50%, 0%);
    position: absolute;
    top: 100px;
    left: 50%;
  }
}

// About sections

.section-header {
  display: inline-block;
  width: 100%;
  color: $light;
  font-size: 30px;
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 5px;
  vertical-align: middle;
}

.section-about div {
  padding-bottom: 5px;
}

.section-legal h1 {
  color: $light;
  font-size: 24px;
}

.section-legal div {
  color: $light;
  font-size: 16px;
  padding-bottom: 5px;
}

// Footer for inMotion
.footer {
  padding: 0;
  margin: 0;
  height: $FOOTER_BG_HEIGHT;
  background-color: $FOOTER_BG_COLOUR;
  position: fixed;
  width: 100%;
  vertical-align: middle;
  bottom: 0;
  z-index: 1000;
}

.footer .left {
  display: none;
}

.footer .copyright {
  left: 0;
  right: 0;
  text-align: center;
  bottom: 3px;
  font-size: $FOOTER_HANDHELD_TEXT_SIZE;
  color: $FOOTER_TEXT_COLOUR;
}

.footer .copyright a {
  font-size: $FOOTER_HANDHELD_TEXT_SIZE;
  color: $FOOTER_TEXT_COLOUR;
}

@media (min-width: 500px) {
  .footer .left {
    display: inline-block;
    float: left;
    margin-left: 30px;
    padding: 0;
    bottom: 3px;
    color: $FOOTER_TEXT_COLOUR;
  }

  .footer .copyright {
    float: right;
    margin-right: 10px;
    text-align: right;
    display: inline;
    //  margin-top: 5px;
    //  margin-bottom: 5px;
    bottom: 3px;
    font-size: $FOOTER_TEXT_SIZE;
    color: $FOOTER_TEXT_COLOUR;
  }

  .footer .copyright a {
    font-size: $FOOTER_TEXT_SIZE;
    color: $FOOTER_TEXT_COLOUR;
  }
}

.inmotion-store-image-border {
  border: 0.25px solid #ffffff;
  border-radius: 4px;
}

.app-title {
  font-style: italic;
  font-weight: bold;
  font-family: 'Verdana', sans-serif;
}
